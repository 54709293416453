import React from 'react'
import './About.css'

const SkillsMobile = () => {
   return (
      <div className='mobile-skills'>
         <h5 className='mobile-titles'>Front-End</h5>
         <p className='pb-2'>React, JavaScript, HTML, CSS</p>
         <h5 className='mobile-titles'>Back-End</h5>
         <p className='pb-2'>Python, Node.js, SQL, Pandas</p>
         <h5 className='mobile-titles'>Development Tools</h5>
         <p className='pb-2'>Command Line, Git, Heroku</p>
      </div>
   )
}

export default SkillsMobile